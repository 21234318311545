<template>
  <div class="home">
    <div v-if="this.alertStatus"  class="alert alert-danger" role="alert" style="position: sticky; top: 0; z-index: 15; background-color: rgba(119, 30, 30, 0.853);">
      <h2 style="color: aliceblue">{{ this.alertMessage }}</h2>
    </div>
    <h1 class="title">Ma'lumot olish uchun xaritadan hududni tanlang</h1>
    <div class="map-container">
      <!-- Image Map Generated by http://www.image-map.net/ -->
      <img src="../assets/andijan-map-1.png" usemap="#image-map" class="responsive-image" @load="resizeMap">

      <map name="image-map">
        <area target="" alt="Andijon" title="Andijon" href="/zones/1" coords="354,129,381,113,390,110,415,106,450,122,447,142,462,164,448,185,435,200,422,197,410,202,408,215,374,230,361,227,348,230,341,217,362,190,350,166,349,139" shape="poly">
        <area target="" alt="Paxtaobod" title="Paxtaobod" href="/zones/2" coords="341,39,366,23,372,1,377,18,382,23,395,30,410,29,431,45,436,65,446,69,458,72,472,101,475,121,466,123,426,103,404,103,391,105,403,90,410,79,394,74,378,80,358,88,360,68,343,55" shape="poly">
        <area target="" alt="Asaka" title="Asaka" href="/zones/3" coords="301,182,338,210,338,222,344,241,358,252,354,264,338,268,318,283,302,299,286,294,272,282,254,266,277,255,293,247,284,237,290,227,289,213,291,202,290,189" shape="poly">
        <area target="" alt="Oltinko'l" title="Oltinko'l" href="/zones/4" coords="346,144,344,149,347,165,357,183,352,193,340,208,331,200,314,187,304,183,286,185,266,183,254,173,250,161,222,158,217,144,227,133,239,129,258,145,268,154,300,150" shape="poly">
        <area target="" alt="Izboskan" title="Izboskan" href="/zones/5" coords="339,37,298,51,295,66,283,89,278,114,291,127,306,133,329,139,338,140,339,128,344,118,354,125,362,112,379,109,394,97,404,82,394,74,362,90,354,87,357,69,340,55" shape="poly">
        <area target="" alt="Marhamat" title="Marhamat" href="/zones/6" coords="307,293,331,283,354,271,370,261,379,268,376,285,383,297,394,311,390,322,391,330,398,354,386,377,384,389,373,393,362,381,344,367,336,369,329,361,318,354,319,343,305,339,299,342,290,333,299,321,310,303" shape="poly">
        <area target="" alt="Xo'jaobod" title="Xo'jaobod" href="/zones/7" coords="390,230,399,223,416,216,414,206,425,200,433,205,436,213,446,215,454,233,467,241,478,248,490,257,502,270,522,277,539,289,538,297,529,290,518,290,509,285,498,289,502,318,495,328,483,329,471,324,464,314,470,305,470,285,479,278,466,265,447,250,430,247,407,244,398,241" shape="poly">
        <area target="" alt="Buloqboshi" title="Buloqboshi" href="/zones/8" coords="374,258,364,249,362,237,366,227,375,229,389,237,395,241,414,244,438,249,454,259,462,269,474,277,467,284,462,289,464,305,456,312,442,310,424,304,411,299,406,285,391,265" shape="poly">
        <area target="" alt="Jalaquduq" title="Jalaquduq" href="/zones/9" coords="454,125,514,144,555,145,551,169,534,172,538,186,528,202,523,212,486,233,502,255,534,242,546,239,548,247,547,261,537,284,516,271,502,268,490,256,477,238,458,231,450,217,443,205,454,198,455,185,467,165,450,144" shape="poly">
        <area target="" alt="Qo'rg'ontepa" title="Qo'rg'ontepa" href="/zones/10" coords="506,256,542,240,579,242,589,234,606,213,645,192,633,168,612,161,586,138,557,143,560,151,556,172,539,172,538,188,531,202,537,210,514,216,487,233" shape="poly">
        <area target="" alt="Xonobod" title="Xonobod" href="/zones/11" coords="646,192,691,191,711,183,715,167,719,161,714,149,706,147,664,145,653,146,631,162" shape="poly">
        <area target="" alt="Baliqchi" title="Baliqchi" href="/zones/12" coords="213,153,208,143,214,127,226,130,232,121,254,132,267,147,280,148,294,148,308,145,328,142,321,136,309,133,299,135,292,127,276,124,272,114,265,106,250,97,237,103,206,96,189,85,173,81,158,84,135,88,110,95,122,119,136,120,134,130,141,152,164,165,184,165,199,159" shape="poly">
        <area target="" alt="Bo'z" title="Bo'z" href="/zones/13" coords="164,169,146,178,150,185,136,187,136,225,141,238,172,257,210,264,212,271,215,257,205,243,210,234,214,225,206,221,199,225,194,212,194,197,197,187,190,175,178,176,173,170" shape="poly">
        <area target="" alt="Ulug'nor" title="Ulug'nor" href="/zones/14" coords="108,97,120,121,132,130,130,137,140,153,161,166,143,173,136,184,135,224,105,207,78,197,68,181,56,191,58,225,50,230,37,249,16,203,-1,184,38,177,30,156,14,138,32,129,45,120,62,121,95,97" shape="poly">
     
      </map>
    </div>

    <div class="all-zones">
      <table class="table table-striped table-primary table-hover">
        <thead>
            <tr>
            <th scope="col">#</th>
            <th scope="col">Hudud nomi</th>
            <th scope="col">Hududning havo holati</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(value, key, index) in this.zonesAndConditions" :key="index">
              <th scope="row">{{ index+1 }}</th>
              <td>{{ key }}</td>
              <td>{{ value }}</td>
            </tr>
        </tbody>
      </table>
    </div>
    
    <div class="container mt-5" style="backdrop-filter: blur(10px) brightness(150%) contrast(90%); padding: 4%;">
      <h3 class="title">Hudud qo'shish</h3>
      <div class="row">
        <div class="col-3">
          <select class="form-select" aria-label="Default select example" v-model="this.zoneName">
            <option value="" disabled selected>Hududni tanlang</option>
            <option value="Andijon-1">Andijon</option>
            <option value="Paxtaobod-2">Paxtaobod</option>
            <option value="Asaka-3">Asaka</option>
            <option value="Oltinko'l-4">Oltinko'l</option>
            <option value="Izboskan-5">Izboskan</option>
            <option value="Marhamat-6">Marhamat</option>
            <option value="Xo'jaobod-7">Xo'jaobod</option>
            <option value="Buloqboshi-8">Buloqboshi</option>
            <option value="Jalaquduq-9">Jalaquduq</option>
            <option value="Qo'rg'ontepa-10">Qo'rg'ontepa</option>
            <option value="Xonobod-11">Xonobod</option>
            <option value="Baliqchi-12">Baliqchi</option>
            <option value="Bo'z-13">Bo'z</option>
            <option value="Ulug'nor-14">Ulug'nor</option>
          </select>
        </div>
        <div class="col-6">
          <input type="text" class="form-control" id="input2" v-model="this.deviceLocation" placeholder="Qurilma o'rnatilgan joyni kiriting" width="30%">
        </div>
        <div class="col-2" style="bottom: 0; align-self: flex-end">
          <button class="btn btn-primary" @click="addZoneHandler">Hududni qo'shish</button>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import ImageMapResize from 'image-map-resizer';
import axios from 'axios';

export default {
  data() {
    return {
      zoneName: '',
      deviceLocation: '',
      alertStatus: false,
      alertMessage: '',
      zonesAndConditions: {}
    }
  },
  mounted() {
    this.resizeMap();
    this.getAllZonesAndConditions();
  },
  methods: {
    resizeMap() {
      ImageMapResize();
    },
    async addZoneHandler(){
      const zone = {
        zoneName: this.zoneName.split('-')[0],
        zoneId: this.zoneName.split('-')[1],
        deviceLocation: this.deviceLocation
      }
      await axios.post('/zone/add', zone)
      .then((resp) => {
        this.zoneName = ''
        this.deviceLocation = ''
        this.alertMessage = 'Muvaffaqqiyatli ro\'yxatga olindi !'
        this.alertStatus = true
        this.hideAlertAfterDelay();
      })
      .catch((error) => {
        console.log(error)
        if(error.response.data.message === 'This zone already exist in the system'){
          this.alertMessage = 'Ushbu hudud allaqachon ro\'yxatga olingan !'
          this.alertStatus = true
          this.hideAlertAfterDelay();
        } else if(error.response.data.error === 'This action is only for admins'){
          this.alertMessage = 'Hudud qo\'shish faqat adminlar uchun'
          this.alertStatus = true
          this.hideAlertAfterDelay();
        }
      })
    },
    hideAlertAfterDelay() {
        setTimeout(() => {
          this.zoneName = ''
          this.deviceLocation = ''
          this.alertStatus = false;
          location.reload()
        }, 3000); // 2000ms = 2 seconds
    },
    async getAllZonesAndConditions() {
      await axios.get('/zone/conditions')
      .then((response) => {   

        // this.zonesAndConditions = response.data.zonesDict
        const zonesDict = response.data.zonesDict
        for(const element in zonesDict){
          // console.log(element + ' ' + zonesDict[element])
          if(zonesDict[element] <= 60){
            this.zonesAndConditions[element] = 'Yaxshi'
          } else if(zonesDict[element] > 60 && zonesDict[element] <= 120){
            this.zonesAndConditions[element] = 'Zararli'
          } else if(zonesDict[element] > 120){
            this.zonesAndConditions[element] = 'Xavfli'
          } else if(zonesDict[element] === 'No device installed in this area'){
            this.zonesAndConditions[element] = 'Qurilma o\'rnatilmagan'
          } else {
            this.zonesAndConditions[element] = 'Hudud faol emas'
          }
        }

      })
    }
  },
};
</script>

<style scoped>
.home {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-image: url("../assets/background-earth.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  text-align: center;
}

.title {
  color: white;
  font-size: 2rem;
  margin-bottom: 20px;
}

.map-container {
  position: relative;
  width: 100%;
  max-width: 45vw; /* Adjust max-width to be responsive */
  margin: 0 auto;
}

.responsive-image {
  width: 100%;
  height: auto;
  display: block;
}

.all-zones {
  margin-top: 8vh;
  width: 70vw;
  backdrop-filter: blur(10px) brightness(150%) contrast(90%); 
  padding: 15px;
 
}



@media (max-width: 600px) {
  .title {
    font-size: 1.5rem;
  }

  .map-container {
    max-width: 90%; /* Reduce max-width on smaller screens */
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 1.2rem;
  }

  .map-container {
    max-width: 100%; /* Ensure it takes full width on very small screens */
  }
}
</style>
